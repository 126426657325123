<template>
    <div class="nav-box">
        <div class="nav-content flex flex-center">
            <div class="back flex flex-center" v-if="back" @click="goBack">
                <img src="../assets/back.png" alt="">
                <span>返回</span>
            </div>
            <p class="title">{{title}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'navBox',
    props: {
        title:String,
        back:Boolean,
        callback:Function,
    },
    data(){
        return {

        }
    },
    mounted(){
        
    },
    methods:{

        goBack(){
            if(this.callback){
                this.callback()
            }else if(this.title == '选择支付方式'){
                var data = {
                    consumptionAreaId:localStorage.getItem("consumptionAreaId"),
                    consuptionSubjectIds:localStorage.getItem("consuptionSubjectIds").split(",")
                }
                this.$post(this.Api.unprint,data).then(res =>{
                    this.$router.go(-1)
                })
            }else if(this.title == '选择模式'){
                uni.postMessage({
                    data:{
                        type:'goBack'
                    }
                })
            }else{
                 this.$router.go(-1)
            }
           
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav-box{
    width: 100%;
    padding: 70px 30px;
    .nav-content{
        width: 100%;
        position: relative;
        .back{
            position: absolute;
            left: 0px;
            img{
                width: 44px;
                height: 44px;
            }
            span{
                font-size: 44px;
                font-weight: 700;
                color: #1A1E22;
                margin-left: 20px
            }
        }
        .title{
            font-size: 44px;
            font-weight: 700;
            color: #1A1E22;

        }
    }
}
</style>
